import API from '../../api';
import SVLS_API from '../svls-api';

export const getUserPersonalDetails = async () => {
  return await API.get('/user/personal-details', {
    headers: {
      Authorization: sessionStorage.getItem('jwt_token'),
    },
  });
}

export const updateUserPersonalDetails = async (data) => {
  return await API.put('/user/personal-details', data, {
    headers: {
      Authorization: sessionStorage.getItem('jwt_token'),
      'Content-Type': 'application/json',
    },
  });
}

export const updateUserDetails = async (data) => {
  return await API.put('/user/dob', data, {
    headers: {
      Authorization: sessionStorage.getItem('jwt_token'),
      'Content-Type': 'application/json',
    },
  });
}

export const getUserButtonVariables = async () => {
  return await API.get('/user/button-variables', {
    headers: {
      Authorization: sessionStorage.getItem('jwt_token'),
    },
  })
}

export const updateUserButtonVariables = async (data) => {
  return await API.post('/user/button-variables', data, {
    headers: {
      Authorization: sessionStorage.getItem('jwt_token'),
    },
  })
}

export const updateUserPassword = async (data) => {
  return await API.put('/user/password', data, {
    headers: {
      Authorization: sessionStorage.getItem('jwt_token'),
      'Content-Type': 'application/json',
    },
  });
}

export const forgotUserPassword = async (data) => {
  return await API.put('/user/forgot-user-password', data, {
    headers: {
      Authorization: sessionStorage.getItem('jwt_token'),
      'Content-Type': 'application/json',
    },
  });
}

export const getBettingCurrency = async () => {
  return await API.get(
    '/user/betting-currency',
    {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
    });
}

export const updateBettingCurrency = async (currency) => {
  return await API.put(
    '/user/betting-currency',
    { currency: currency },
    {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
        'Content-Type': 'application/json',
      },
    });
}

export const resetUserPassword = async (data) => {
  return await API.put('/user/reset_user_password', data, {
    headers: {
      Authorization: sessionStorage.getItem('jwt_token'),
      'Content-Type': 'application/json',
    },
  });
}

export const getUserSettings = async () => {
  return await API.get('/user/settings', {
    headers: {
      Authorization: sessionStorage.getItem('jwt_token'),
    },
  });
}

export const updateUserSettings = async (data) => {
  return await API.put(
    '/user/settings',
    data,
    {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
    }
  );
}

export const getUserResetPwdStatus = async (resetKey) => {
  return await API.get(`/user/reset-password/${resetKey}`);
}

export const fetchUserBalance = async () => {
  return await API.get('/user/balance-summary', {
    headers: {
      Authorization: sessionStorage.getItem('jwt_token'),
    },
  });
}

export const getUserAllowedConfig = async () => {
  if (!sessionStorage.getItem('jwt_token')) {
    return await SVLS_API.get(
      '/catalog/v2/settings/catalog-config'
    );
  } else {
    return await SVLS_API.get(
      '/catalog/v2/settings/catalog-config',
      {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      }
    );
  }
}

