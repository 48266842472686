import cricketIcon from '../assets/images/sidenav/cricket.svg';
import soccerIcon from '../assets/images/sidenav/soccer.svg';
import tennisIcon from '../assets/images/sidenav/tennis.svg';
import horceRachingIcon from '../assets/images/sidenav/hourse.svg';

export const EXCHANGE_EVENT_TYPES = [
  { id: '4', name: 'Cricket', slug: 'cricket' },
  { id: '1', name: 'Football', slug: 'football' },
  { id: '2', name: 'Tennis', slug: 'tennis' },
  { id: '7', name: 'Horse Racing', slug: 'horseracing' },
  // { id: '4339', name: 'Greyhound', slug: 'greyhoundracing' },
  // { id: '7511', name: 'Baseball', slug: 'baseball' },
  // { id: '7522', name: 'Basketball', slug: 'basketball' },
];

export const SPORTS_MAP = new Map([
  [
    'Cricket',
    {
      id: '4',
      name: 'Cricket',
      slug: 'cricket',
      img: cricketIcon,
      priority: 0,
      disable: false,
      showOnMobileInplay: true,
    },
  ],
  [
    'Football',
    {
      id: '1',
      name: 'Football',
      slug: 'football',
      img: soccerIcon,
      priority: 1,
      disable: false,
      showOnMobileInplay: true,
    },
  ],
  [
    'Tennis',
    {
      id: '2',
      name: 'Tennis',
      slug: 'tennis',
      img: tennisIcon,
      priority: 2,
      disable: false,
      showOnMobileInplay: true,
    },
  ],
  [
    'Horse Racing',
    {
      id: '7',
      name: 'Horse Racing',
      slug: 'horseracing',
      img: horceRachingIcon,
      priority: 3,
      disable: false,
      showOnMobileInplay: false,
    },
  ],
]);

export const EXCH_SPORTS_MAP = {
  tennis: '2',
  football: '1',
  soccer: '1',
  cricket: '4',
  baseball: '7511',
  basketball: '7522',
  greyhoundracing: '4339',
  horseracing: '7',
};
